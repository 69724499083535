'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = trackForMutations;
function trackForMutations(isImmutable, ignore, obj) {
  var trackedProperties = trackProperties(isImmutable, ignore, obj);
  return {
    detectMutations: function detectMutations() {
      return _detectMutations(isImmutable, ignore, trackedProperties, obj);
    }
  };
}

function trackProperties(isImmutable) {
  var ignore = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
  var obj = arguments[2];
  var path = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : [];

  var tracked = { value: obj };

  if (!isImmutable(obj)) {
    tracked.children = {};

    for (var key in obj) {
      var childPath = path.concat(key);
      if (ignore.length && ignore.indexOf(childPath.join('.')) !== -1) {
        continue;
      }

      tracked.children[key] = trackProperties(isImmutable, ignore, obj[key], childPath);
    }
  }
  return tracked;
}

function _detectMutations(isImmutable) {
  var ignore = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
  var trackedProperty = arguments[2];
  var obj = arguments[3];
  var sameParentRef = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : false;
  var path = arguments.length > 5 && arguments[5] !== undefined ? arguments[5] : [];

  var prevObj = trackedProperty ? trackedProperty.value : undefined;

  var sameRef = prevObj === obj;

  if (sameParentRef && !sameRef && !Number.isNaN(obj)) {
    return { wasMutated: true, path: path };
  }

  if (isImmutable(prevObj) || isImmutable(obj)) {
    return { wasMutated: false };
  }

  // Gather all keys from prev (tracked) and after objs
  var keysToDetect = {};
  Object.keys(trackedProperty.children).forEach(function (key) {
    keysToDetect[key] = true;
  });
  Object.keys(obj).forEach(function (key) {
    keysToDetect[key] = true;
  });

  var keys = Object.keys(keysToDetect);
  for (var i = 0; i < keys.length; i++) {
    var key = keys[i];
    var childPath = path.concat(key);
    if (ignore.length && ignore.indexOf(childPath.join('.')) !== -1) {
      continue;
    }

    var result = _detectMutations(isImmutable, ignore, trackedProperty.children[key], obj[key], sameRef, childPath);

    if (result.wasMutated) {
      return result;
    }
  }
  return { wasMutated: false };
}